import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import { Link } from 'gatsby'
import colors from '../components/tokens/colors'
import {alignVertical} from '../components/tokens/positionings'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <Complete>404 Page Not Found<span>ページが見つかりません</span><Link to="./">TOPへ</Link></Complete>
  </Layout>
)

const Complete = styled.h1`
text-align: center;
padding: 40px;
background: ${colors.cl_red};
color: ${colors.cl_white};
font-size: 1.8rem;
${alignVertical};
span {
  padding-top:12px;
font-size: 1.4rem;
}
a {
  font-size: 1.4rem;
  padding: 10px 20px;
  border: 1px solid white;
  margin-top: 40px;
}
`
export default NotFoundPage
